import { MAX_HOURS_PER_WEEK } from 'modules/loading-screen/schedule-commit/constants';

const localizedStrings = {
  emailPlaceholder: 'Email',
  passwordPlaceholder: 'Password',
  signupHeader: 'Your Journey Begins',
  signupSubtitle: 'Sign up for your Next Great Adventure',
  namePlaceholder: 'First and Last Name',
  signupCodePlaceholder: 'Quest Code',
  signupAcknowledgement1:
    "By continuing you indicate that you've read and agree to our",
  signupAcknowledgement3: 'and',
  signupRegisterBtn: 'Sign Up',
  signupAcceptTerms: 'Accept',
  alreadyHaveAnAccount: 'I’m already signed up!',
  loginHeader: 'Welcome back!',
  loginForgotPassword: 'Forgot your password?',
  loginSignInBtn: 'Sign In',
  register: 'Sign Up',
  codeVerificationHeaderBegin: 'Welcome back',
  codeVerificationHeaderEnd: '!',
  codeVerificationSubtitle: 'Please wait while we validate your account',
  codeVerificationPlaceHolder: 'Verification Code',
  codeVerificationBtn: 'Confirm Your Email',
  codeVerificationAcknowledgment1: "Can't find your Verification Code?",
  codeVerificationAcknowledgment2: 'Resend',
  linkToCopy: 'Copy Link to Share',
  linkCopied: 'Link copied successfully',
  copying: 'Copying',
  forgotPasswordHeader: 'Forgot your password?',
  forgotPasswordSubtitle:
    'Please enter your email to receive a password reset code',
  forgotPasswordMainBtn: 'Reset Password',
  forgotPasswordCancelBtn: 'Cancel',
  resetPasswordHeader: 'Reset your password',
  resetPasswordPlaceHolder1: 'Verification Code',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  repeatPassword: 'Repeat Password',
  updatePassword: 'Update Password',
  resetPasswordCancelBtn: 'Cancel',
  passwordRequiredMessage: 'Please enter a password',
  passwordMinLengthMessage: 'Password must be at least 8 characters',
  passwordDifferentMessage: "The passwords you entered don't match",
  emailRequiredMessage: 'Please enter your email',
  emailValidMessage: 'Please enter a valid email',
  verificationCodeRequiredMessage:
    'Please enter the Verification Code we sent to your email',
  verificationCodeMatchesMessage: 'The Verification Code includes only numbers',
  verificationCodeMinMessage: 'The Verification Code has at least 5 digits',
  verificationCodeMaxMessage: 'The Verification Code has at most 5 numbers',
  nameRequiredMessage: 'Please enter your first and last name',
  nameMaxLength: 'Your name cannot contain more than 50 characters',
  errorServerDown: `Sorry, we ran into some trouble, please try again in a few minutes!`,
  errorAuthentication: `Incorrect email or password, please verify your credentials and retry.`,
  errorDefault: `Sorry, we ran into some trouble, please try again later.`,
  errorVerificationCode: `The Verification Code is not valid.`,
  errorCohortCode: `The Quest Code is not valid.`,
  errorCohortAlreadyAdded: `You are already on that quest.`,
  errorCurrentPassword: `Current password is wrong.`,
  errorActiveQuestNotCompleted: `The user associated with the quest must complete its current active quest before activating a trial.`,
  errorQuizResultNoCohort: `The quiz result is not associated with any sample quest.`,
  errorNoQuestContentful: `There's been an issue retrieving the content definition of the quest. Please contact the admin.`,
  errorNoQuestUser: `There's been an issue retrieving the user information for the quest. Please contact the admin.`,
  errorNoAppletDef: `There's been an issue retrieving some applet content definition. Please contact the admin.`,
  errorNotFoundUser: `There's been an issue retrieving the related user information. Please contact the admin.`,
  errorMissingFileUpload: `There's been an issue while submitting the uploaded file information. Please try again or contact the admin.`,
  errorMissingRichTextContent: `There's been an issue while submitting rich text content of your applet. Please try again or contact the admin.`,
  errorMissingInlineText: `There's been an issue while submitting the text content of your applet. Please try again or contact the admin.`,
  errorMissingQuizQuestions: `There's been an issue while the answers you provided to the quiz. Please try again or contact the admin.`,
  errorMissingFileInformation: `There's been an issue while submitting the information related to your uploaded files. Please try again or contact the admin.`,
  errorMissingMadlibAnswers: `There's been an issue while submitting the madlib information. Please try again or contact the admin.`,
  errorMissingRankOption: `There's been an issue while submitting the ranking information. Please try again or contact the admin.`,
  errorMissingRandomChoice: `There's been an issue while submitting randomly chosen option. Please try again or contact the admin.`,
  errorCohortNoQuestDef: `There's been an issue while retrieving the associated quest to the cohort in the database.`,
  errorNoCohortDelete: `There's been an issue retrieving the information of the cohort you are trying to delete.`,
  errorAddUserNoCohort: `There's been an issue retrieving the information of the cohort you are trying to add the user to.`,
  errorPostForTask: `There's been an error submitting challenge information for a task.`,
  errorChallengeCompleted: `You are trying to complete a challenge that has been already completed. Please refresh the page.`,
  errorWeekContentNoQuestDef: `There's been an error while retriving a quest's content definition. Please try again or contact the admin.`,
  errorWeekContentNoContentContentful: (
    additionalInformation: Record<string, string>,
  ) =>
    `There's been an error with the week configuration of the content.(weekUserId: ${additionalInformation.weekUserId}, weekContentfulId: ${additionalInformation.weekContentfulId}, challengeUserId: ${additionalInformation.challengeUserId}, challengeContentfulId: ${additionalInformation.challengeContentfuiId}).`,
  errorWeekContentNoWeekUser: (additionalInformation: Record<string, string>) =>
    `There's been an error while retrieving the user information for one of the weeks (questContentfulId: ${additionalInformation.questContentfulId}, questUserId: ${additionalInformation.questUserId})`,
  errorNoChallengeUser: `There's been an error retrieving the user submittted information for the current challenge.`,
  errorNoChallengeContentful: `There's been an error retrieving the content definition for the current challenge.`,
  errorCompleteStepWrongContent: `There's been an error completing the step for an unknown content type.`,
  errorCompleteStepNoChallenge: `The step appears to be not part of this challenge anymore.`,
  errorRestoreNoBackup: `The backup you are trying to restore seems to be missing.`,
  errorUnsubscribeNoEmail: `The email address you provided is not subscribed to our emails.`,
  errorGiveFeedbackNoPost: `There's been an error retrieving the submission's information.`,
  errorPostNoContent: `There's been an error while sending the feedback notification.`,
  errorNoHelpContentful: `There's been an error retrieving the help information.`,
  errorNoLegalContentful: `There's been an error retrieving the legal information.`,
  errorPlaylistArtifactNoReading: `There's been an error while retrieving the artifact's related reading.`,
  errorCompleteArtifactNoWeekUser: `There's been an error while retrieving the week user information for the current playlist artifact.`,
  errorCompleteArtifactNoDef: `There's been an error while retriving the artifact's information.`,
  errorSubmitPostWrongContent: `There's been an error with the content type configuration. Please contact the admin.`,
  errorGetPostWrongId: `There's been an error while retrieving the information of the submission. Please try again or contact the admin.`,
  errorGetSubmissionNoAuthor: `There's been an error while retrieving the information of the author of the submission. Please try again or contact the admin.`,
  errorGetSubmissionNoRelationship: `There's been an error while retrieving the information related to user's submission. Please try again or contact the admin.`,
  errorGetSubmissionNoChallengeInformation: `There's been an error while retrieving the associated content to the submission. Please try again or contact the admin.`,
  errorSharePostNoRelationship: `There's been an error while retrieving the information about the user. Please try again or contact the admin.`,
  errorUpserOffboardingNoQuest: `There's been an error while retrieving the quest information associated to the onboarding.`,
  errorSharePostWrongRelationship: `There's been an error while trying to send the submission to the user. Please try again or contact the admin.`,
  errorGetSubmissionNoCohort: `There's been an error while retrieving your fellow Travelers group information. Please try again or contact the admin.`,
  errorInviteEmail: `There's been an error while sending your invitation. Please try again or contact the admin.`,
  errorSwitchRPNoRelatedUser: `There's been an error while retrieving your running partner information. Please try again or contact the admin.`,
  errorAcceptInvitationNoRelationship: `There's been an error while retrieving the invitation information. Please try again or contact the admin.`,
  errorPostScheduleNoQuest: `There's been an error while retrieving the your information about the quest. Please try again or contact the admin.`,
  errorGetScheduleConfigNoQuest: (args: Record<string, string>) =>
    `There's been an error while retrieving the user's schedule as the quest for which the schedule was requested seems to be missing. User Quest Id: ${args.questUserId}`,
  errorGetScheduleConfigNoWeekSpec: (args: Record<string, string>) =>
    `There's been an error while retrieving the schedule of the week of the user. The week seems to not be registered in the database. User Quest Information Id: ${args.questUserId}, User Week Information Id: ${args.weekUserId}.`,
  errorGetScheduleConfigNoWeekContentful: (args: Record<string, string>) =>
    `There's been an error while retrieving the schedule of the week of the user. Can't retrieve the week from contentful. User Quest Information Id: ${args.questUserId}, User Week Information Id: ${args.weekUserId}, Week Contentful Id: ${args.weekContentfulId}.`,
  errorEditScheduleNoWeeks: `There's been a problem while retrieving the user information of the weeks. Please try again or contact the admin.`,
  errorGetShowcaseNoShowcase: `There's been a problem while retrieving the showcase. Please try again or contact the admin.`,
  errorGetShowcaseNoUser: `There's been a problem while retrieving the author's information. Please try again or contact the admin.`,
  errorHeadlineAppletsNoAppletContentful: `There's been a problem while retrieving one of the headline artifacts. Please try again or contact the admin.`,
  erronBuildAppletsNoAppletContentful: `There's been a problem while retrieving one of the showcase's artifacts. Please try again or contact the admin.`,
  errorBuildAppletsNoContentUser: `There's been a problem while retrieving the user's content information. Please try again or contact the admin.`,
  errorGetShocaseListNoUser: `There's been a problem while retrieving the user's information. Please try again or contact the admin.`,
  errorShareShowcaseNoShowcase: `There's been a problem while retrieving the information of the showcase you want to share. Please try again or contact the admin.`,
  errorContactShowcaseOwnerNoShocase: `There's been a problem while retrieving the information of the showcase. Please try again or contact the admin.`,
  errorContactShowcaseOwnerNoUser: `There's been a problem while retrieving the user information related to the showcase. Please try again or contact the admin.`,
  errorGetContentNoContent: `There's been a problem while retriebing the content user information of the showcase. Please try again or contact the admin.`,
  errorPostExistingUser: `This email is already been used by another user.`,
  errorPostUserNoCohort: `The cohort you are associating the user to can't be found.`,
  errorPatchUserNoActive: `The active flag is not set correctly.`,
  errorPatchUserNoUser: `There's been a problem while retriving the user information of the user you are trying to update.`,
  errorDeleteUserSameUser: `You can't delete your own user.`,
  errorDeleteUserNoUser: `There's been an error while retrieving the user information of the user you are trying to delete.`,
  errorSignUpUsedEmail: `The email address you provided is already used by another user.`,
  errorAddCohortNoUser: `There's been an error while retriving the information of the user. Please try again or contact the admin.`,
  errorQuizResultCohortNotFound: `There has been a problem while retrieving information about the sample quest. Please try again or contact the admin.`,
  errorGetWeeksForQuestNoQuest:
    "There has been a problem while retriving the user's quest information.",
  errorCompleteStepParallelOperation:
    'It looks like you are trying to complete the step at the same time from two different instances or platforms. Refresh the page to check if the step has been actually completed. In the case the issue persists please contact the admin.',
  errorCompleteStepNoStepContentful: `There seems to be a problem retrieving step information from contentful. Please try again.`,
  errorCompleteStepStoredProcedure:
    'There was a problem while submitting the information. Please try again.',
  errorCompleteStepNoUpdatedChallenge: `There was a problem retrieving the updated content. Please refresh the page.`,
  errorNotFoundActiveQuest: "The user's active quest has been removed.",
  errorContentWeekDefMissing: "Apparently a Contentful configuration content error was detected. You must verify that the weeks are correctly associated with the Quest. Don't forget to update entities in Contentful to refresh relationships and update published changes.",
  errorContentWeekDefPlaylistMissing: "Apparently a Contentful configuration content error was detected. You must verify that the Playlist Artifacts are correctly associated with the Playlists. The Playlists to the Weeks. And the Weeks to the Quest. Don't forget to update entities in Contentful to refresh relationships and update published changes.",
  inviteRunningPartnerTitle: 'Invite a Running Partner',
  inviteRunningPartner:
    'Invite someone from your close circle who you can share your work with to celebrate your accomplishments and to receive invaluable feedback and encouragement from.',
  addMentorTitle: 'Add a Mentor-Guide',
  inviteMentorTitle: 'Invite a Mentor-Guide',
  inviteMentor:
    'A Mentor-Guide is a special person you find to accompany you on your journey and will encourage and support you to achieve the best results.',
  relationships: 'Relationships',
  titleEmptyPlaylist: `Your playlist is empty`,
  textEmptyPlaylist: 'Select "+" to see all available media and select your favorites to view later.',
  searchPlaceholder: 'Search',
  pairedWithRunningPartnerTitle: 'You have paired with a Running Partner!',
  pairedWithRunningPartnerSubtitle:
    'and you are now paired as Running Partners',
  pairedWithRunningPartnerBtnText: 'Got it!',
  mentoringFellowTravelerTitle: 'You are now mentoring a Fellow Traveler!',
  mentoringFellowTravelerSubtitle: 'is your new mentored traveler',
  mentoringFellowTravelerBtnText: 'Got it!',
  acceptInvitation: 'Accept',
  declineInvitation: 'Decline',
  cancelInvitation: 'Cancel',
  invite: 'Invite',
  pair: 'Pair',
  add: 'Add',
  defaultRunningPartnerInviteMessage: 'Become my Running Partner',
  defaultRunningPartnerInviteTitle: "You've been invited!",
  acceptRunningPartnerPushTitle: "You've been paired!",
  acceptRunningPartnerPushBody: 'Congratulations! You have a Running Partner!',
  acceptRunningPartnerNotifBody: (displayName: string) =>
    `${displayName} is your new Running Partner`,
  defaultMentorInviteMessage: 'Become my Mentor-Guide',
  defaultMentorInviteTitle: "You've been invited!",
  acceptMentorPushTitle: "You've been paired!",
  acceptMentorPushBody: 'Congratulations! You have a Running Partner!',
  acceptMentorNotifBody: (displayName: string) =>
    `${displayName} is your new Running Partner`,
  notebook: 'Notebook',
  reviews: 'Reviews',
  pairWithRunningPartner: 'Pair with a Running Partner',
  addSomeoneElse: 'Add someone else',
  runningPartnerNameRequiredMessage:
    'Please enter the name of your running partner',
  mentorNameRequired: 'Please enter the name of your mentor',
  runningPartnerEmailRequiredMessage:
    'Please enter the email of your running partner',
  mentorEmailRequiredMessage: 'Please enter the email of your mentor',
  invitationSent: 'Your invitation has been sent',
  gotIt: 'Got it!',
  invitationSentDescription: (invitedUserName: string) =>
    `If ${invitedUserName} accepts your invitation you will be paired as Running Partners`,
  mentorInvitationSentDescription: (invitedUserName: string) =>
    `If ${invitedUserName} accepts your invitation they will become your Mentor`,
  awesome: 'Awesome',
  chat: 'Chat',
  sendFeedback: 'Send Feedback',
  shareYourWork: 'Share your work',
  shareMessagePrompt: 'Please enter a message',
  delete: 'Delete',
  deleteShowcase: 'Delete Showcase',
  deleteShowcasePrompt: 'Are you sure you want to delete this showcase?',
  share: 'Share',
  shareDescription:
    'We’ll email your submission to someone you choose.  Type a brief message to ask for their feedback.',
  enrollmentCodeRequiredMessage: 'Please enter the Quest Code',
  enrollmentCodeMatchesMessage: 'The Quest Code includes only numbers',
  enrollmentCodeMinMessage: 'Your Quest Code has at least 5 numbers',
  enrollmentCodeMaxMessage: 'Your Quest Code has at most 6 numbers',
  settings: 'Settings',
  getHelp: 'Get Help',
  getHelpTitle: 'How can we help you?',
  getHelpSubtitle: 'Answers to Frequently Asked Questions.',
  switchQuests: 'Switch Quests',
  manageSettings: 'Manage Settings',
  fellowTravelers: 'Fellow Travelers',
  runningPartner: 'Running Partner',
  errorVisibilityPermission: 'This submission has not been shared with you.',
  yourQuests: 'Your Quests',
  cohortScheduledOn: (date: string) => `Started on ${date}`,
  cohortScheduledOff: (date: string) => `Closed on ${date}`,
  cohortCompletedOn: (date: string) => `Completed on ${date}`,
  cohortLastVisitOn: (date: string) => `Last visited on ${date}`,
  inProgress: ' (in progress)',
  newQuest: 'New Quest',
  newQuestAffirmIncompleteState: `You are asking to join a new quest, but you haven't finished your current one. You can only participate in one quest at a time.
  Are you sure you want to abandon your current quest and start this new one?`,
  newQuestAffirmCompleteState: `Your next quest awaits. Are you ready to start the next step of your journey?`,
  newQuestPrompt: 'Please enter a Quest Code to start your next quest.',
  switchQuest: 'Switch Quest',
  switchQuestState: `You are asking to change your quest, but you haven't finished your current one. You can only participate in one quest at a time.
  Are you sure you want to abandon your current quest and continue with the other one?`,
  questCode: 'Quest Code',
  getStarted: 'Get Started',
  giveUsFeedback: 'Give us feedback',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  changePassword: 'Change Password',
  changePasswordSuccess: 'Password updated successfully',
  signOut: 'Sign Out',
  deleteAccount: 'Delete Account',
  messageDeleteAccount:
    'Deleting your account will delete all your records from our databases. Your progress in NGA and your data account will be unrecoverable.',
  sureDeleteAccount:
    'Are you sure you want to permanently delete your account?',
  switchRunningPartner: 'Switch Running Partner',
  today: 'Today',
  yesterday: 'Yesterday',
  goBack: 'go back',
  or: 'or',
  loadMore: "Load more",
  loading: "Loading...",
  loadingDelete:
    'Please wait while we are removing all your data from our records',
  done: 'Done',
  todo: 'To do',
  feedbackPrompt:
    'What do you love, what is not working, what would you like us to change/add?',
  writeYourFeedback: 'Write your feedback here',
  cancel: 'Cancel',
  examples: 'Examples',
  rubrics: 'Rubrics',
  tips: 'Tips',
  goDeeper: 'Go Deeper',
  switchRunningPartnerDescription:
    'Your current Running Partner will get a notification of your reason. Once you disconnect, you are free to Pair with a Running Partner again.',
  switchRunningPartnerPrompt: 'Why do you want to switch your Running Partner?',
  runningPartnerIsUnresponsive: 'Running Partner is unresponsive',
  runningPartnerIsUnresponsiveNotification:
    "%display_name% thinks you have not been responsive so they're no longer your running partner",
  runningPartnerIsNotHelpful: 'Running Partner is not helpful',
  runningPartnerIsNotHelpfulNotification:
    "%display_name% thinks you have not been very helpful so they're no longer your running partner",
  foundNewRunningPartner: "I've found a new Running Partner",
  foundNewRunningPartnerNotification:
    "%display_name% thinks someone else would be a better running partner for them so they've unmatched you",
  addShowcase: 'Add a Showcase',
  mySchedule: 'My Schedule',
  noChallengeDefault:
    'No more challenges today … but you can always check your Go Deeper Playlist or work ahead!',
  noChallengeQuestNotStarted: (days: number) =>
    `Your quest officially starts in ${days} days, but you can get started by picking a task below.`,
  noChallengeQuestStarted:
    'You have no tasks scheduled for today, but you can keep working by picking a task below.',
  noChallengeQuestCompleted: (sampleQuest: boolean) => `${sampleQuest ?
    `Congratulations, you've finished the sample quest! Select Profile to review your work.` :
    'Congratulations, you’ve finished the quest! Select from below to review your work.'}`,
  errorContentDetectedQuest:
    'We detected a problem with the quest content, please contact the admin (admin@mynextgreatadventure.org) if you need further assistance.',
  contentNotAvailable:
    'This content is not available.',
  reflectRequiredMessage:
    'You must complete this section if you want to complete the challenge.',
  fileRequiredMessage:
    'You must upload a file in order to complete the challenge',
  congratulations: 'Congratulations!',
  earned: 'You’ve earned',
  nextUp: 'Next Up',
  bonusChallenge: 'Bonus Challenge',
  returnToHome: 'Return Home',
  visibilityPermissions: 'Privacy: Share By Default With',
  achievements: 'Achievements',
  publishedOn: 'Published on ',
  noPublishedShowcases: 'No published showcases',
  publishedShowcases: 'Published Showcases',
  createShowcase: 'Create Showcase',
  publishShowcase: 'Publish showcase',
  publishAShowcase: 'Publish a showcase',
  showcaseName: 'Showcase name',
  showcaseCallToAction: 'Call to action',
  selectShowcaseName: 'Please enter a title for this showcase:',
  selectShowcaseCallToAction:
    'Please enter your Call to Action for this showcase:',
  selectShowcaseArtifacts: 'Select the artifacts you want to include:',
  selectShowcaseFeatured:
    'Select the three most important artifacts that you want to feature in this showcase:',
  selectShowcaseBackground: 'Select an image for your showcase background:',
  selectShowcaseVideo:
    'Select a video you would like to feature in your showcase:',
  selectShowcaseHeadline:
    'Select the element that you would like to be the headline of this showcase',
  selectAll: 'Select all',
  selectChoice: 'Select your choice',
  deselectAll: 'Deselect all',
  back: 'Back',
  next: 'Next',
  selectAtLeast3Artifacts: 'Select at least 3 artifacts',
  select3Artifacts: 'Select 3 Artifacts',
  selectAnArtifact: 'Select an Artifact',
  continue: 'Continue',
  showcaseAppletError:
    'You must complete some challenges before you are able to share a showcase, please return after you have finished Week 1.',
  showcaseImageError:
    'First, add an image in your Notebook to use as a showcase background.',
  showcaseVideoError:
    'First, add at least one video in your Notebook to use in your showcase.',
  showcaseNameValidation: 'Please enter a name for your Showcase',
  showcaseCallToActionValidation: 'Please enter a call to action text',
  shareEmailValidation: 'Please, enter a email to share the showcase',
  shareNameValidation: 'Please, enter a name',
  shareMessageValidation: 'Please, enter a message',
  shareMessagePlaceholder: 'Message',
  viewShowcase: 'View',
  uploadFile: 'Upload a file',
  uploadRichText: 'Add a text',
  errorMessageRichTextModalIncomplete:
    'The field has not been edited. Please input your content before uploading.',
  setupScheduleCommit: 'Set Up Your Schedule',
  daysWorkInTheApp: 'Which days will you work?',
  finishChallengeScheduleCommit: (hours: number) =>
    `Plan for at least ${hours} hours each week.`,
  hoursSelected: (hours: number) => `${hours} hours selected`,
  scheduleAlertContent: (minHours: number) =>
    `You must schedule between ${minHours} and ${MAX_HOURS_PER_WEEK} hours each week.`,
  yes: 'Yes',
  no: 'No',
  edit: 'Edit',
  editSchedule: 'Edit Schedule',
  editSchedulePrompt:
    'Would you like to update all future weeks with this schedule?',
  start: 'Start',
  profile: 'Profile',
  reflect: 'Reflect',
  submitFeedbackPrompt:
    'Rate the submission 1 to 5 stars, then provide some text feedback to explain your rating.',
  feedbackAlreadySubmitted: 'You have already submitted your feedback',
  feedbackMessageValidation: 'You need to leave a message for your feedback.',
  leaveFeedback: 'Leave feedback',
  noChalenges: 'There are no challenges for this day',
  recentActivity: 'Recent activity',
  runningPartnerCheckingIn: 'Your Running Partner is checking in.',
  fellowTravelerCheckingIn: 'A Fellow Traveler is checking in',
  runningPartnerChat: 'Your Running Partner has sent you a chat.',
  fellowTravelerChat: 'A Fellow Traveler has sent you a chat.',
  journey: 'Journey',
  overview: 'Overview',
  gridView: 'Grid View',
  timelineView: 'Timeline View',
  firstNameRequired: 'Please tell us what your first name is',
  lastNameRequired: 'Please tell us what your last name is',
  messageRequired: 'You need to leave a message',
  feedbackSent: 'Thank you for your feedback',
  feedbackSentMessage: `We’ll use your feedback to improve the experience.`,
  emailSent: 'Message Sent!',
  emailSentMessage: 'Thank you for contacting me and I’ll be in touch soon.',
  playVideo: 'Play video',
  showText: 'Show text',
  loadingNewWeek: 'Please wait while we prepare your path for the week ahead.',
  loadingNewQuest: 'Please wait while we load the new quest content.',
  onTheGoPlaylist: 'Go Deeper Playlist',
  thisWeek: 'This Week',
  video: 'Watch',
  readings: 'Read',
  audio: 'Listen',
  all: 'All',
  ask: 'Ask',
  goToVideo: 'Go to the video',
  goToBook: 'Go to the book',
  goToPodcast: 'Go to the podcast',
  goToReading: 'Go to the reading',
  goToWebArticle: 'Go to the web Article',
  goToSocraticQuestion: 'Go to the socratic question.',
  artifactReadPrompt: (title: string) => `Have you completed "${title}"?`,
  readingAudio: 'Reading Audio',
  completeApplets: 'Please complete all artifacts.',
  ngaParticipant: 'NGA Participant',
  wellDone: 'Well Done',
  weekCompletedTitle: (weekTitle: string) => `${weekTitle} promise kept.`,
  questCompletedTitle: (questTitle: string) => `${questTitle} promise kept`,
  reviewAchievementTitle: (score: number) => `${score} star review.`,
  completedOnSchedule: 'Completed on schedule',
  send: 'Send',
  shareAppletContentfulIdRequired:
    'There seems to be an error while retrieving applet information. Try to exit and enter again to the challenge.',
  shareAppletRequired: 'Please complete the applet.',
  shareAppletToRequired: 'Please enter an email to which to send the email.',
  shareAppletToEmail: 'Please enter a valid email address.',
  shareAppletSubjectRequired: 'Please enter a subject for the email.',
  newMessage: 'New Message',
  emailSuccess: 'The mail has been sent.',
  shareAppletTo: 'To:',
  shareAppletSubject: 'Subject: ',
  shareAppletMessage: 'Additional Message (optional):',
  areYouSure: 'Are you sure?',
  loseChanges: 'Any unsaved changes will be lost if you move to another step',
  completeStepConfirmation:
    'We have detected some uncompleted applets. Do you still wish to complete the applet?',
  formatErrorMessage:
    'Please upload a supported file format. We currently support audios, images, videos(.mp4) and documents(.txt, .pdf)',
  startOnSameWeek: 'Change start date?',
  startOnSameWeekPrompt:
    'This quest was scheduled to start on Monday. Since you are signing up past the start date, you can push the start to next Monday (Reschedule) or continue with the original schedule (Start Late).',
  startLate: 'Start Late',
  reschedule: 'Reschedule',
  dailyReminder: 'Daily Reminder',
  dailyReminderMessage:
    'If you’ve enabled push notifications, we’ll send reminders on your scheduled days. Set the time you would like to receive notifications:',
  daily: 'Daily',
  weekly: 'Weekly',
  behindSchedule: 'Behind Schedule',
  completedSteps: (stepsCompleted: number, totalSteps: number) =>
    stepsCompleted === totalSteps
      ? 'COMPLETED'
      : `${stepsCompleted}/${totalSteps} COMPLETED`,
  phoneNeeded: 'Phone needed',
  completeMadlibTitle: 'Complete the MadLib',
  save: 'Save',
  phoneRecommended: 'Mobile',
  computerRecommended: 'Keyboard recommended',
  desktopRecommended: 'Desktop recommended',
  filter: 'Filter',
  completed: 'Completed',
  late: 'Late',
  addOption: 'Add Option',
  addOptionsTitle: 'Add at least 3 options',
  optionRequired: 'You must enter a value for the new option',
  minimumOptions:
    'You must enter at least 3 option in order to use this applet.',
  addOptionsButton: 'Add and Rank Your Options',
  closeButton: 'Close',
  seeResultsButton: 'See Results',
  quizResultsTitle: 'Quiz Results',
  stepCompletionFileError:
    'Something went wrong while uploading some of the files. Please try again. If this error persists please contact the admin.',
  yourName: 'Your Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  reset: 'Reset',
  chooseRandomly: 'Throw a dart',
  addRandomSelectionOptionsButton: 'Add Your Option(s)',
  seeResults: 'See Results',
  optional: 'Optional',
  editQuizButton: 'Edit Quiz',
  experience: (bonusChallenge?: boolean) => `${bonusChallenge ? 'BP' : 'XP'}`,
  seeQuiz: 'See Quiz',
  showRank: 'Show Rank',
  showDecisionTree: 'Show Decision Tree',
  mentorGuide: 'Mentor-Guide',
  externalMentorGuide: 'External Mentor-Guide',
  invalidApplet: 'The applet id you tried to provide is not correct.',
  incompleteApplet: (appletTitle = 'missing applet title') =>
    `You must complete the artifact: ${appletTitle}.`,
  invalidMadlibInput: 'The madlib input has not been completed.',
  invalidRankAppletComponent: 'The rank option indicated is not correct.',
  quizAppletComponent: 'The quiz option has not been selected',
  missingUserInformation:
    'There seems to be a problem while retrieving user information.',
  errorNoInternetConnection:
    'You seem to not be connected to internet. Please restore the connection.',
  errorNoActiveQuest: "Sorry we can't connect to your quest at this time.",
  errorUnindentifiedError: (userName: string, email: string) =>
    `It looks like an unidentified error is ocurring while ${userName} (${email}) is trying to access the app.`,
  loadingScreenErrorMessage: `Sorry we can't connect to your quest at this time. Please contact our team for additional assistance.`,
  loadingScreenGenericErrorMessage: `Sorry, the content isn't loading. Please refresh the page to try again. If the problem persists, please contact admin@mynextgreatadventure.org for further assistance.`,
  refreshPage: 'Reload page',
  quitErrorBtnText: 'Quit',
  reportErrorBtnText: 'Report to admin',
  errorBackendDown:
    "It looks like we're having temporary issues accessing your information. Please try to login again in a few minutes.",
  accountDeleted: 'Your account was successfully deleted',
  errorAccountDeleted:
    'An error occurred while trying to delete your account. Please try again in a few minutes',
  verifyEmailAccount: 'Verify your email',
  later: 'Later',
  sentYouAnEmail: (email: string) =>
    `Please complete your Sign Up by selecting 'Validate your account' in the email we've sent to ${email}.`,
  availableMedia: 'Available Media',
  returnPlaylist: 'Return to Playlist',
  editContent: 'Edit',
  saveContent: 'Save Changes',
  artifacts: 'Artifacts',
  challenges: 'Challenges',
  quests: 'Quests',
  showcases: 'Showcases',
  submit: 'Submit',
  showcaseGetInTouch: "Get in touch! I’d like to hear from you.",
  ok: 'OK',
  completeAllApplets: "Please complete all entries or selections.",
  cantContinue: "Something’s missing",
  showcaseCreated: "Showcase was successfully created",
  showcaseShared: "Showcase was successfully shared",
  showcaseDeleted: "Showcase was successfully deleted",
  doNextChallenge: "Do Next Challenge",
  youHavePendingTasks: "You have pending tasks, switch quest to resume them",
  noArtifacts: "No artifacts completed",
  logoutMsg: 'Are you sure you want to log out?',
  location: 'Location'
};

export default localizedStrings;

import { createReducer } from '@reduxjs/toolkit';
import { signup } from 'modules/auth/actions';
import {
  addCohort,
  getCohorts,
  getCohortStartingDate,
  getLocations,
  userCohortExists,
} from './actions';
import { SettingsState } from './typings';

const initialState: SettingsState = { cohorts: [], locations: [] };

export default createReducer<SettingsState>(initialState, (builder) =>
  builder
    .addCase(getCohorts.fulfilled, (state, action) => ({
      ...state,
      cohorts: action.payload,
    }))
    .addCase(getCohortStartingDate.fulfilled, (state, action) => ({
      ...state,
      cohortStartingDate: action.payload,
    }))
    .addCase(getCohortStartingDate.rejected, (state) => ({
      ...state,
      cohortStartingDate: undefined,
    }))
    .addCase(getCohortStartingDate.pending, (state) => ({
      ...state,
      cohortStartingDate: undefined,
    }))
    .addCase(signup.fulfilled, (state) => ({
      ...state,
      cohortStartingDate: undefined,
    }))
    .addCase(addCohort.fulfilled, (state) => ({
      ...state,
      cohortStartingDate: undefined,
    }))
    .addCase(addCohort.rejected, (state) => ({
      ...state,
      cohortStartingDate: undefined,
    }))
    .addCase(userCohortExists, (state, action) => ({
      ...state,
      userCohortExist: action.payload,
    }))
    .addCase(getLocations.fulfilled, (state, action) => ({
      ...state,
      locations: action.payload,
    })),
);

import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import {
  DisplayNameForm,
  EditIconContainer,
  EditIconContainerIcon,
  Email,
  UserName,
  UserNameContainer,
} from 'modules/profile/components';
import EditIcon from './EditIcon';
import InputField from 'components/input-field';
import profileDisplayNameSchema from './profileDisplayNameSchema';
import { profileDisplayNameInitialValues } from './profileDisplayNameInitialValues';
import { UpdateProfileDisplayNamePayload } from 'modules/auth/typings';
import { updateProfileDisplayName } from 'modules/auth/actions';
import LocalizedStrings from 'localization';
import useActionStatus from 'hooks/useActionStatus';
import ButtonPrimary from 'components/button-primary';
import { selectFirstName, selectLastName } from 'modules/auth/selectors';

type UserNameSectionProps = {
  displayName?: string;
  email?: string;
};

const UserNameSection = ({ displayName, email }: UserNameSectionProps) => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdatingName] = useActionStatus(updateProfileDisplayName);
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);

  const onValidSubmit = useCallback(
    (values: UpdateProfileDisplayNamePayload) => {
      dispatch(updateProfileDisplayName(values));
      setIsEditMode(false);
    },
    [dispatch],
  );

  const onEditClick = useCallback(() => {
    setIsEditMode(true);
  }, []);

  return isEditMode ? (
    <Formik
      initialValues={profileDisplayNameInitialValues(firstName, lastName)}
      validationSchema={profileDisplayNameSchema}
      onSubmit={onValidSubmit}>
      <DisplayNameForm>
        <InputField
          name="firstName"
          placeholder={LocalizedStrings.firstName}></InputField>
        <InputField
          name="lastName"
          placeholder={LocalizedStrings.lastName}></InputField>
        <ButtonPrimary type="submit">{LocalizedStrings.save}</ButtonPrimary>
      </DisplayNameForm>
    </Formik>
  ) : (
    <UserNameContainer>
      <EditIconContainer onClick={onEditClick}>
        <UserName>{displayName}</UserName>
        <Email>{email}</Email>
      </EditIconContainer>
      <EditIconContainerIcon>
        {isUpdatingName ? <CircularProgress size={28} /> : <EditIcon width='28px' height='28px' />}
      </EditIconContainerIcon>
    </UserNameContainer>
  );
};

export default UserNameSection;

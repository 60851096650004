import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/typings';

import { selectQuestsContentful } from 'modules/loading-screen/contentful/quests/selectors';
import { selectQuestsOnboardings } from 'modules/loading-screen/contentful/quests-onboardings/selectors';
import { selectQuestsOffboardings } from 'modules/loading-screen/contentful/quests-offboardings/selectors';

const selectQuestsState = (state: RootState) => state.quests;

export const selectQuestsHash = createSelector(
  selectQuestsState,
  (questsState) => questsState.quests,
);

export const selectQuestIds = createSelector(selectQuestsHash, (questsHash) =>
  Object.values(questsHash)
    .sort((questA, questB) => {
      const firstDate = new Date(questA.startingDate);
      const secondDate = new Date(questB.startingDate);
      return secondDate.getTime() - firstDate.getTime();
    })
    .map((quest) => quest.id),
);

export const selectActiveQuest = createSelector(
  selectQuestsState,
  (questsState) =>
    questsState.activeQuestId
      ? questsState.quests[questsState.activeQuestId]
      : undefined,
);

export const selectActiveQuestId = createSelector(
  selectActiveQuest,
  (quest) => quest?.id ?? '',
);

export const selectActiveQuestCohortId = createSelector(
  selectActiveQuest,
  (quest) => quest?.cohortId,
);

export const selectActiveQuestWeekIds = createSelector(
  selectActiveQuest,
  (quest) => quest?.weekIds ?? [],
);

export const selectActiveQuestContentful = createSelector(
  selectActiveQuest,
  selectQuestsContentful,
  (selectedQuest, questsContentful) =>
    selectedQuest ? questsContentful[selectedQuest.contentfulId] : undefined,
);

export const selectActiveQuestContentfulWeekIds = createSelector(
  selectActiveQuestContentful,
  (questContentful) => questContentful?.weekIds,
);

export const selectActiveQuestTitle = createSelector(
  selectActiveQuestContentful,
  (questContentful) => questContentful?.title,
);

export const selectActiveQuestOnboardings = createSelector(
  selectActiveQuest,
  selectQuestsOnboardings,
  (quest, questOnboardings) =>
    quest?.onboardingIds
      ?.map((onboardingId) => questOnboardings[onboardingId])
      .sort((onboardingA, onboardingB) => onboardingA.number - onboardingB.number),
);

export const selectActiveQuestOffboardings = createSelector(
  selectActiveQuest,
  selectQuestsOffboardings,
  (quest, questOffboardings) =>
    quest?.offboardingIds
      ?.map((offboardingId) => questOffboardings[offboardingId])
      .sort((offboarding) => offboarding.number),
);

export const selectActiveQuestStartingDate = createSelector(
  selectActiveQuest,
  (quest) => quest?.startingDate,
);

export const selectActiveQuestCompletedAt = createSelector(
  selectActiveQuest,
  (quest) => quest?.completedAt,
);

export const selectLoadingFirstFinished = createSelector(
  selectQuestsState,
  (questsState) => questsState.loadingFirstFinished,
);

export const selectLoadingLastFinished = createSelector(
  selectQuestsState,
  (questsState) => questsState.loadingLastFinished,
);

export const selectLoadingScheduleCommitFinished = createSelector(
  selectQuestsState,
  (questsState) => questsState.loadingScheduleCommitFinished,
);

export const selectLoadingLastContentFinished = createSelector(
  selectQuestsState,
  (questsState) => questsState.loadingHomeFinished,
);

export const selectLoadingFinished = createSelector(
  selectQuestsState,
  (questsState) => questsState.loadingFinished,
);

export const selectLoadingAdditionalFinished = createSelector(
  selectQuestsState,
  (questsState) => questsState.loadingAdditionalFinished,
);

export const selectIsQuestCompleted = createSelector(
  selectActiveQuest,
  (selectedQuest) => Boolean(selectedQuest?.completedAt),
);

export const selectHasAddedQuest = createSelector(
  selectQuestsState,
  (questsState) => questsState.hasAddedQuest,
);

export const selectCompleteQuestAdded = createSelector(
  selectQuestsState,
  (questsState) => questsState.completeQuestAdded,
);

export const selectIsSampleQuest = createSelector(
  selectActiveQuest,
  selectQuestsContentful,
  (quest, questsContentful) => {
    const contentfulId = quest?.contentfulId || '';
    const activeQuestDefinition = questsContentful[contentfulId];
    return activeQuestDefinition?.isSampleQuest || false;
  },
);

export const selectHasErrorsContentInActiveQuest = createSelector(
  selectQuestsState,
  (questsState) => questsState.errorContent || false
)

export const selectCohortOrganizationId = createSelector(
  selectActiveQuest,
  (quest) => quest?.cohortOrganizationId,
);
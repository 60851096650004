import React, { useEffect } from 'react';
import { ProfileSectionDashboardContainer } from 'modules/profile/components';
import UserProfile from 'modules/profile/user-profile';
import localizedStrings from 'localization';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAchievementsLength,
  selectPublishedShowcasesLength,
} from 'modules/profile/selectors';
import { selectCohortsLength } from 'modules/settings/selectors';
import { selectAvailableAppletsLength } from 'modules/applets/selectors';

import { ProfileCountersItem } from './ProfileCountersItem';
import prizeIcon from 'assets/icons/Prize.webp';
import rocketIcon from 'assets/icons/Rocket.webp';
import notebookIcon from 'assets/icons/Notebook.webp';
import medalOkIcon from 'assets/icons/Medal.webp';
import ArtifactsGalleryFeatured from 'modules/profile/artifacts-gallery-featured';
import { getCohorts, getLocations } from 'modules/settings/actions';
import { AppDispatch } from 'redux/typings';
import { selectCohortOrganizationId } from 'modules/loading-screen/quests/selectors';

export type CounterItem = {
  id: number;
  title: string;
  icon: string;
  quantity: number;
  link: 'ManageQuests' | 'AchievementsList' | 'NotebookGrid' | 'ShowcasesList';
};

export const ProfileSectionDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();

  const achievementsCounter = useSelector(selectAchievementsLength);
  const cohortsCounter = useSelector(selectCohortsLength);
  const artifactsCouter = useSelector(selectAvailableAppletsLength);
  const showcasesCounter = useSelector(selectPublishedShowcasesLength);
  const organizationId = useSelector(selectCohortOrganizationId)

  useEffect(() => {
    dispatch(getCohorts());
  }, [dispatch]);

  useEffect(() => {
    organizationId && dispatch(getLocations(organizationId));
  }, [dispatch, organizationId])


  const COUNTER_ITEMS: CounterItem[] = [
    {
      id: 0,
      title: localizedStrings.quests,
      icon: rocketIcon,
      quantity: cohortsCounter,
      link: 'ManageQuests',
    },
    {
      id: 1,
      title: localizedStrings.challenges,
      icon: medalOkIcon,
      quantity: achievementsCounter,
      link: 'AchievementsList',
    },
    {
      id: 2,
      title: localizedStrings.artifacts,
      icon: notebookIcon,
      quantity: artifactsCouter,
      link: 'NotebookGrid',
    },
    {
      id: 3,
      title: localizedStrings.showcases,
      icon: prizeIcon,
      quantity: showcasesCounter,
      link: 'ShowcasesList',
    },
  ];

  return (
    <ProfileSectionDashboardContainer>
      <UserProfile />
      {COUNTER_ITEMS.map((item, index) => (
        <ProfileCountersItem key={index} item={item} />
      ))}
      <ArtifactsGalleryFeatured />
    </ProfileSectionDashboardContainer>
  );
};

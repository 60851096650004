import styled from "@emotion/styled";

export const Container = styled.div`
  width: 80%;
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 8px;  
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const StyledOption = styled.option`
  font-size: 1rem;
`;
import styled from '@emotion/styled';
import Lottie from 'react-lottie-player';

import ButtonPrimary from 'components/button-primary';
import { TextArea } from 'components/components';

export const UploadPrompt = styled.div`
  margin-bottom: 1rem;
`;

export const InlineAppletInput = styled(TextArea)`
  border: none;
  padding: 0;
`;

export const InlineAppletText = styled.div`
  margin-bottom: 1rem;
`;

export const GroupAppletContainer = styled.div`
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 0.7rem;
  resize: none;
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  margin-bottom: 2rem;
  min-width: 100%;  
`;

export const DecisionTreeInput = styled.input`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  width: 22rem;
  margin-top: -1rem;
  margin-bottom: 2rem;
  padding: 0.7rem 1.2rem 0.5rem 0.8rem;
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 0.7rem;
  outline: none;
`;

export const DecisionTreePreviewContainer = styled.div`
  border-radius: 0.6rem;
  height: 24rem;
  width: 24rem;
  max-width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0.8rem;
`;

export const DecisionTreePreviewImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.6rem;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const DecisionTreePreviewTopImage = styled.div`
  height: calc(50% - 1px);
  width: 100%;
`;

export const DecisionTreePreviewVerticalSeparator = styled.div`
  width: 2px;
  background-color: ${(props) => props?.theme?.colors.grey[90]};
`;

export const DecisionTreePreviewHorizontalSeparator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props?.theme?.colors.grey[90]};
`;

export const DecisionTreePreviewBottomImage = styled.div`
  height: calc(50% - 1px);
  width: calc(50% - 1px);
`;

export const DecisionTreeImagePreviewContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const DecisionTreeImageTag = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const DecisionTreeImageDescription = styled.div`
  position: absolute;
  background-color: white;
  bottom: 1rem;
  font-size: ${(props) => props?.theme?.typography.large16};
  line-height: ${(props) => props?.theme?.typography.large24};
  color: ${(props) => props?.theme?.colors.blue2[100]};
  padding: 0 1rem 0 1rem;
  border-radius: 0 1.3rem 1.3rem 0;
`;

export const RankAppletContainer = styled.div`
  width: 30rem;
  max-width: 100%;
  margin: 1rem 0;
`;

export const RankOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-bottom: 12px;
`;

export const RankIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props?.theme?.colors.grey[70]};
  padding: ${(props) => props?.theme?.typography.large18}
    ${(props) => props?.theme?.typography.large16};
  margin-right: ${(props) => props?.theme?.typography.large10};
  border-radius: 10px;
  color: ${(props) => props?.theme?.colors.blue2[100]};
`;

export const RankOptionTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props?.theme?.colors.grey[70]};
  border-radius: 10px;
  flex: 1;
  padding: ${(props) => props?.theme?.typography.large24}
    ${(props) => props?.theme?.typography.large20};
`;

export const RankOptionBurgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RankOptionBurgerLine = styled.div`
  height: 2px;
  width: 16px;
  background-color: ${(props) => props?.theme?.colors.grey[60]};
`;

export const RankOptionBurgerSpace = styled.div`
  height: 2px;
`;

export const MadlibAppletContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props?.theme?.typography.large24};
`;

export const MadlibAppletTextContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${(props) => props?.theme?.colors.grey[70]};
  padding: ${(props) => props?.theme?.typography.large24};
  padding-bottom: ${(props) => props?.theme?.typography.large32};
`;

export const MadlibEditIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -${(props) => props?.theme?.typography.large16};
`;

export const RandomSelectionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const RandomSelectionButtonAddOptionsButton = styled(ButtonPrimary)`
  width: 50%;
  margin-bottom: ${(props) => props?.theme?.typography.large18};
`;

export const RandomSelectionSubmitButton = styled(ButtonPrimary)`
  width: 50%;
`;

type RandomSelectionOptionComponentProps = {
  selected?: boolean;
  disabled?: boolean;
};

export const RandomSelectionOptionContainer = styled.div<RandomSelectionOptionComponentProps>`
  border: 1px solid
    ${(props) =>
    props.selected
      ? props?.theme?.colors.optionRed
      : props.disabled
        ? props?.theme?.colors.grey[90]
        : props?.theme?.colors.grey[100]};
  border-radius: 10px;
  margin-bottom: ${(props) => props?.theme?.typography.large18};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: border 1s;
  position: relative;
`;

export const RandomSelectionOptionText = styled.div<RandomSelectionOptionComponentProps>`
  margin-bottom: 0px;
  flex-grow: 1;
  color: ${(props) =>
    props.disabled
      ? props?.theme?.colors.grey[90]
      : props?.theme?.colors.dark[100]};
  transition: color 1s;
  padding: ${(props) => props?.theme?.typography.large18};
  margin-right: ${(props) => (props.selected ? '6%' : '0px')};
`;

export const AnimationContainer = styled(Lottie)`
  position: absolute;
  height: 220%;
  right: -50%;
  width: 100%;
`;

export const EditIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

export const EditIconContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  flex: 1;
  width: 25rem;
  justify-content: space-between;
  align-items: center;
`;

export const LocationUserTitle = styled.div`
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
`;

export const EditIconLocationContainer = styled.div`  
  display: flex;
  align-items: center;  
`;

export const ArtifactInput = styled.input`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  border-radius: 0.7rem;
  padding: 0.5rem 0.7rem 0.5rem 0.8rem;
  margin-bottom: 0.5rem;  
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props?.theme?.colors.grey[90]};
  }
  ::placeholder {
    color: ${(props) => props?.theme?.colors.grey[90]};
    opacity: 1;
  }
  &:disabled {
    background-color: ${(props) => props?.theme?.colors.grey[80]};
    color: ${(props) => props?.theme?.colors.grey[90]};
  }
    width: 100%;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CancelButton = styled(ButtonPrimary)`
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  width: 10rem;
  margin: 0 1rem 0 0;
`;


export const SaveButton = styled(ButtonPrimary)`
  width: 10rem;
  margin: 0;
`;

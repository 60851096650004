import debounce from 'lodash/debounce';
import memo from 'lodash/memoize';

import {
  LoginPayload,
  LoginData,
  SignupPayload,
  Signup,
  EmailPayload,
  VerifyPayload,
  ResetPasswordPayload,
  UpdateProfilePicturePayload,
  LegalDocument,
  UpdateProfileDisplayNamePayload,
  VisibilityAchievementsPayload,
  UpdateProfileLocationPayload,
} from './typings';
import HttpClient from '../../services/HttpClient';
import { baseService } from '../baseService';
import { Document } from '@contentful/rich-text-types';

export const login = baseService<LoginPayload, LoginData>((data) =>
  HttpClient.post('/login', data),
);

export const updateProfilePicture = baseService<
  UpdateProfilePicturePayload,
  string
>((data) => HttpClient.patch('/users/profile/picture', data));

export const updateProfileDisplayName = baseService<
  UpdateProfileDisplayNamePayload,
  string
>((data) => HttpClient.patch('/users/profile/name', data));

export const updateProfileVisibilityAchievements = baseService<
  VisibilityAchievementsPayload,
  string
>((data) => HttpClient.patch('/users/profile/visibility-achievements', data));


export const updateProfileLocation = baseService<
  UpdateProfileLocationPayload,
  string
>((data) => HttpClient.patch('/users/profile/location', data));

const baseUrl = '/users';

export const signup = baseService<SignupPayload, Signup>((data) =>
  HttpClient.post(baseUrl, data),
);

export const resendCode = baseService<EmailPayload, Signup>((data) =>
  HttpClient.post(`${baseUrl}/resend`, data),
);

export const verifyCode = baseService<VerifyPayload, LoginData>(
  ({ verificationCode, email }) =>
    HttpClient.post(`${baseUrl}/verify`, { verificationCode, email }),
);

export const forgotPassword = baseService<EmailPayload, Signup>((data) =>
  HttpClient.post(`${baseUrl}/passwordForgotten`, data),
);

export const resetPassword = baseService<ResetPasswordPayload, Signup>((data) =>
  HttpClient.post(`${baseUrl}/reset`, data),
);

export const isEmailUnique = debounce(
  memo(
    baseService<EmailPayload, boolean>((data) =>
      HttpClient.get(
        `${baseUrl}/unique-email?email=${data.email.replaceAll('+', '%2b')}`,
      ),
    ),
  ),
  300,
  { leading: true },
);

export const getLegalDoc = baseService<LegalDocument, Document | undefined>(
  (docName) => HttpClient.get(`/legal?name=${docName}`),
  (response) => response.data.fields.content,
);

export const deleteUser = baseService(() => HttpClient.delete(baseUrl));

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  sendFeedback as sendFeedbackService,
  getCohorts as getCohortsService,
  addCohort as addCohortService,
  changePassword as changePasswordService,
  getCohortStartingDate as getCohortStartingDateService,
  getLocations as getLocationsService,
} from './services';
import { Location } from 'modules/auth/typings';

const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const sendFeedback = createAsyncThunk(
  SEND_FEEDBACK,
  sendFeedbackService,
);

const GET_COHORTS = 'GET_COHORTS';
export const getCohorts = createAsyncThunk(GET_COHORTS, getCohortsService);

const ADD_COHORT = 'ADD_COHORT';
export const addCohort = createAsyncThunk(ADD_COHORT, addCohortService);

const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const changePassword = createAsyncThunk(
  CHANGE_PASSWORD,
  changePasswordService,
);

const GET_COHORT_STARTING_DATE = 'GET_COHORT_STARTING_DATE';
export const getCohortStartingDate = createAsyncThunk(
  GET_COHORT_STARTING_DATE,
  getCohortStartingDateService,
);

const USER_COHORT_EXIST = 'USER_COHORT_EXIST';
export const userCohortExists = createAction<{
  enrollmentCode: string;
  exist: boolean;
}>(USER_COHORT_EXIST);

const GET_LOCATIONS = 'GET_LOCATIONS';
export const getLocations = createAsyncThunk<Location[], string>(
  GET_LOCATIONS,
  getLocationsService
);

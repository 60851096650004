import { useState } from "react";
import { Container, StyledOption, StyledSelect } from "./compontents";

interface Option {
    value: string;
    label: string;
}

interface SelectListProps {
    options: Option[];
    placeholder?: string;
    onChange?: (value: string) => void;
    defaultValue?: string
}

const SelectList: React.FC<SelectListProps> = ({
    options,
    placeholder = "Select your choice",
    onChange,
    defaultValue
}) => {
    const [selectedValue, setSelectedValue] = useState<string>("");

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setSelectedValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Container>
            <StyledSelect
                defaultValue={defaultValue}
                value={selectedValue}
                onChange={handleSelectChange}
            >
                <StyledOption value="" disabled>
                    {placeholder}
                </StyledOption>
                {options.map((option, index) => (
                    <StyledOption key={index} value={option.value}>
                        {option.label}
                    </StyledOption>
                ))}
            </StyledSelect>
        </Container>
    );
};


export default SelectList;
import styled from '@emotion/styled';
import { Form } from 'formik';

import background from 'assets/images/MapBackground.webp';
import ButtonPrimary from 'components/button-primary';
import { ScrollableArea, TextArea } from 'components/components';
import InputField from 'components/input-field';

export const SettingsContainer = styled.div`
  display: flex;
  height: calc(100vh - 4.5rem);
  min-height: 40rem;
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  padding: 2.3rem 3.3rem 2.3rem 3.3rem;
`;

export const ContentMainSection = styled.div`
  flex: 1;
`;

export const ContentSection = styled(ContentMainSection)``;

export const ContentPanel = styled(ScrollableArea)`
  height: 100%;
  border-radius: 0.6rem;
  padding: 1.8rem;
  background-color: rgba(26, 26, 26, 0.8);
`;

export const GiveFeedbackTitle = styled.div`
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 1rem;
`;

export const GiveFeedbackPrompt = styled.div`
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-top: 1rem;
`;

export const GiveFeedbackFormContent = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55rem;
  max-width: 100%;
`;

export const GiveFeedbackFormTextArea = styled(TextArea)`
  color: white;
  margin-top: 1rem;
  width: 100%;
`;

export const GiveFeedbackButton = styled(ButtonPrimary)`
  width: auto;
  align-self: flex-end;
  margin-top: 1rem;
  margin-bottom: 0;
`;

export const FormTitleHeader = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large32};
  line-height: ${(props) => props?.theme?.typography.large41};
  margin-bottom: 1.5rem;
`;

export const FormSubtitle = styled.div`
  color: ${(props) => props?.theme?.colors.white[100]};
  font-size: ${(props) => props?.theme?.typography.large20};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 2.5rem;
`;

export const ManageCohortHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ManageCohortTitle = styled.div`
  font-size: ${(props) => props?.theme?.typography.large32};
  line-height: ${(props) => props?.theme?.typography.large41};
  color: ${(props) => props?.theme?.colors.blue1[100]};
`;

export const ErrorMessage = styled.div`
  color: ${(props) => props?.theme?.colors.red[80]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1rem;
`;

export const AddQuestFormButtons = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
`;

export const AddQuestPrompt = styled.div`
  color: ${(props) => props?.theme?.colors.grey[100]};
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  margin-bottom: 1rem;
`;

export const ManageCohortAddButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  height: fit-content;
  margin-left: 1rem;
`;

export const StartButton = styled(ButtonPrimary)`
  margin: 0;
`;

type SelectedProps = {
  selected: boolean;
};

export const CohortQuestTitle = styled.div<SelectedProps>`
  font-size: ${(props) => props?.theme?.typography.large24};
  line-height: ${(props) => props?.theme?.typography.large32};
  font-weight: ${(props) => (props.selected ? 600 : 0)};
  color: ${(props) =>
    props.selected
      ? props?.theme?.colors.blue2[100]
      : props?.theme?.colors.grey[90]};
  margin-bottom: 0.5rem;
`;

export const CohortScheduledOn = styled.div<SelectedProps>`
  font-size: ${(props) => props?.theme?.typography.large18};
  line-height: ${(props) => props?.theme?.typography.large24};
  font-weight: ${(props) => (props.selected ? 600 : 0)};
  color: ${(props) => props?.theme?.colors.grey[props.selected ? 60 : 100]};
  margin-bottom: 1rem;
`;

export const CohortSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props?.theme?.colors.grey[90]};
  margin-bottom: 1.5rem;
`;
export const ManageQuestCohortContainer = styled.div`
  display: flex;
`;

export const FormTitle = styled.div`
  color: #ffffff;
  font-size: ${(props) => props?.theme?.typography.large32};
  line-height: ${(props) => props?.theme?.typography.large48};
  font-weight: ${(props) => props?.theme?.typography.semiBold};
  margin-bottom: 1rem;
`;

export const ChangePasswordFormContent = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
  max-width: 100%;
`;

export const PrivacyFormContent = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 25rem;
  max-width: 100%;
`;

export const InnerContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 25rem;
  max-width: 100%;
`;

export const ChangePasswordField = styled(InputField)`
  color: white;
`;

export const SectionAccountContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: 55rem;
  max-width: 100%;
`;

export const DeleteAccountButton = styled(ButtonPrimary)`
  width: auto;
  align-self: flex-start;
  margin-top: 1rem;
  margin-bottom: 0;
`;

export const SectionContentPanel = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0.6rem;
  background-color: rgba(26, 26, 26, 0.8);
  padding: 1.8rem;
`;

export const SectionPanel = styled('div')`
  flex: auto;
`;

export const LineBreak = styled('div')`
  border-top: 3px solid white;
  margin-bottom: 1.8rem;
`;

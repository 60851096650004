import { createSelector } from '@reduxjs/toolkit';
import decode from 'jwt-decode';
import fromUnixTime from 'date-fns/fromUnixTime';
import isBefore from 'date-fns/isBefore';

import { RootState } from 'redux/typings';
import { JwtPayload, LegalState } from './typings';

const selectAuthState = (state: RootState) => state.auth;

export const selectIdToken = createSelector(
  selectAuthState,
  (auth) => auth.jwt,
);

const selectTokenPayload = createSelector<
  RootState,
  string | undefined,
  JwtPayload | undefined
>(selectIdToken, (token) => {
  if (!token) {
    return undefined;
  }

  try {
    return decode(token) as JwtPayload;
  } catch {
    return undefined;
  }
});

export const selectIsLoggedIn = createSelector(
  selectTokenPayload,
  (payload) => {
    if (!payload) {
      return false;
    }

    const expiration = fromUnixTime(payload.exp);
    return isBefore(Date.now(), expiration);
  },
);

export const selectUserId = createSelector(
  selectTokenPayload,
  (payload) => payload?.id,
);

export const selectFirstName = createSelector(
  selectTokenPayload,
  (payload) => payload?.firstName,
);

export const selectLastName = createSelector(
  selectTokenPayload,
  (payload) => payload?.lastName,
);

export const selectVisibilityAchievements = createSelector(
  selectTokenPayload,
  (payload) => payload?.visibilityAchievements,
);

export const selectDisplayName = createSelector(
  selectFirstName,
  selectLastName,
  (firstName, lastName) =>
    `${firstName ?? ''}${lastName ? ' ' : ''}${lastName ?? ''}`,
);

export const selectCohortEnrollmentCode = createSelector(
  selectTokenPayload,
  (payload) => payload?.cohortEnrollmentCode,
);

export const selectQuestVersion = createSelector(
  selectTokenPayload,
  (payload) => payload?.questVersion,
);

export const selectIsUserVerified = createSelector(
  selectTokenPayload,
  (payload) => payload?.verified,
);

export const selectEmail = createSelector(
  selectTokenPayload,
  (payload) => payload?.email,
);

export const selectProfilePictureUrl = createSelector(
  selectTokenPayload,
  (payload) => payload?.profilePictureUrl,
);

export const selectQuote = createSelector(
  selectTokenPayload,
  (payload) => payload?.quote,
);

export const selectUserRole = createSelector(
  selectTokenPayload,
  (payload) => payload?.role,
);

export const displayNameSelector = createSelector(
  (state: RootState): string => state.auth.signup.displayName,
  (item: string): string => item,
);

export const emailSelector = createSelector(
  (state: RootState): string => state.auth.signup.email,
  (item: string): string => item,
);

export const verifiedSelector = createSelector(
  (state: RootState): boolean => state.auth.signup.verified || false,
  (item: boolean): boolean => item,
);

export const userIdSelector = createSelector(
  (state: RootState): string => state.auth.signup.id,
  (item: string): string => item,
);

export const selectPrivacyPolicy = createSelector(
  (state: RootState): LegalState => state.auth.legalDocuments,
  (legal) => legal.documents['privacy-policy'],
);

export const selectTermsOfService = createSelector(
  (state: RootState): LegalState => state.auth.legalDocuments,
  (legal) => legal.documents['terms-of-service'],
);

export const selectUserLocation = createSelector(
  selectTokenPayload,
  (payload) => {
    const localization = payload?.localizations?.[0] !== null ? payload?.localizations?.[0]?.description : null;
    const organization = payload?.organizations?.[0] !== null ? payload?.organizations?.[0]?.description : null;
    return localization || organization || null;
  }
);